<template>
    <div class="content">
        <div class="specialTitle">
            <h1>
                {{title}}
            </h1>
            <h2>
                {{subTitle}}
            </h2>
        </div>
        <div id="sales" class='whitebg'>
            <div class="sale" v-for="(data, i) in sold" :key="i">
                <h2>
                    {{data.year}} SALES
                </h2>
                <hr>
                <table>
                <tr v-for="(entry, i) in data.data" :key="i">
                    <td><p>{{entry.Address}}</p></td>
                    <td><p>{{entry.Price}}</p></td>
                    <td><p>{{entry.Date}}</p></td>
                </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.specialTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(90deg, rgba(0,0,0,0.8) 100%, rgba(0, 0, 0, 0.8) 100%), url("/images/salebg.jpg") no-repeat center center;
    background-size: cover;
    height: 75vh;
    margin-top: -60px;
    font-family: 'Catamaran', sans-serif;
    color: white;
    h1{
        font-weight: 400;
        font-size: 5rem;
        text-align: center;
        line-height: 4.5rem;
        letter-spacing: 6px;
        margin: 1rem auto;
        color: #fff;
    }
    h2{
        font-size: 1rem;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 2px;
        margin: 0.5rem auto;
    }
    hr{
        height: 1px;
        width: 4rem;
    }
    p{
        text-align: justify;
        text-align-last: center;
        max-width: 600px;
    }
}
#sales{
    text-align: center;
    padding: 4rem 0;
    margin: 0 auto;
    .sale{
        padding-top: 2rem;
        h2{
            font-size: 1rem;
            letter-spacing: 2px;
        }
        hr{
            width:4rem;
        }
        h3{
            letter-spacing: 2px;
            font-size: 2rem;
            font-weight: 500;
            color: #64b2e6;
            text-align: center;
        }
        table{
            margin: 0 auto;
            margin-top: 1rem;
            border-spacing: 0;
            td {
                padding: 0.15rem 2rem;
            }
            td:nth-child(even){
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
            }
            tr:nth-child(odd) {
                background-color: #f0efef;
            }
            tr:nth-child(even){
                background-color: #f5f5f5;
            }
        }
        p{
            color: #000;
            opacity: 0.65;
            margin: 0.5rem 0;
            white-space: pre-line;
        }
    }
}

@media (max-width: $mobile) {
    .specialTitle{
        h1{
            font-size: 3rem;
            line-height: 3rem;
            letter-spacing: 2px;
            margin: 1rem 20px;
        }
        h2{
            font-size: 0.75rem;
            letter-spacing: 1px;
            margin: 0.5rem auto;
        }
        p{
            margin: 1rem 20px;
        }
    }
    #sales{
        .sale{
            table{
                width: 100%;
                td{
                    padding: 0;
                }
            }
        }
    }
}
</style>

<script>
export default {
    name: "sales",
    methods:{
        getTotalSales(data){
            return data.data.map((item) => {
                return parseInt(item.Price.slice(1).replace(/,/g, ''), 10);
            }).reduce((total, price) => {
                return total + price;
            });
        }
    },
    filters: {
        priceFormat: function (value) {
            if (!value) return ''
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            return formatter.format(value)
        }
    },
    metaInfo: {
        title: 'Sales',
        titleTemplate: '%s | Coastal Luxe',
        meta: [{
            name: 'description', 
            content: "Coastal Luxe"
        }]
    },
    data(){
        return {
            title: "$500+ Million",
            subTitle: "Sales Volume, 2010 - Present",
            content: "\"Give clients what they want, whenever they want.\"",
            columns:[
                {
                    field: 'Address',
                    label: 'Address',
                },
                {
                    field: 'Price',
                    label: 'Price',
                },
                {
                    field: 'Date',
                    label: 'Date',
                    centered: true
                },
            ],
            sold:[
                {
                    "year": "2021",
                    "sales": "123",
                    "data":[
                        {
                            "Address": "1 West Century Drive #8B",
                            "Price": "$4,290,000",
                            "Date": "1/8/21"
                        },
                    ]
                },
                {
                    "year": "2020",
                    "sales": "123",
                    "data":[
                        {
                            "Address": "1905 N Beverly Dr",
                            "Price": "$1,865,000",
                            "Date": "1/8/20"
                        },
                        {
                            "Address": "928 N Croft Ave PH#302",
                            "Price": "$875,000",
                            "Date": "1/9/20"
                        },
                        {
                            "Address": "23626 Malibu Colony Rd #53",
                            "Price": "$65,000/Lease",
                            "Date": "4/28/20"
                        },
                        {
                            "Address": "21324 Pacific Coast Hwy",
                            "Price": "$90,666.67/Lease",
                            "Date": "5/26/20"
                        },
                        {
                            "Address": "23614 Malibu Colony Rd #57",
                            "Price": "$40,000/Lease",
                            "Date": "6/1/20"
                        },
                        {
                            "Address": "23626 Malibu Colony Rd #53",
                            "Price": "$80,000/Lease",
                            "Date": "6/15/20"
                        },
                        {
                            "Address": "23416 Malibu Colony Rd #100",
                            "Price": "$250,000/Lease",
                            "Date": "6/24/20"
                        },
                        {
                            "Address": "838 N Beverly Glen Blvd, Los Angeles",
                            "Price": "$1,500,000",
                            "Date": "7/6/20"
                        },
                        {
                            "Address": "70 Malibu Colony Rd, Malibu",
                            "Price": "$18,275,000",
                            "Date": "7/8/20"
                        },
                        {
                            "Address": "21324 Pacific Coast Hwy",
                            "Price": "$40,000/Lease",
                            "Date": "7/16/20"
                        },
                        {
                            "Address": "23626 Malibu Colony Rd #53",
                            "Price": "$80,000/Lease",
                            "Date": "7/16/20"
                        },
                        {
                            "Address": "411 N. Oakhurst Dr #404",
                            "Price": "$72,000/Lease",
                            "Date": "7/24/20"
                        },
                         {
                            "Address": "27086 Malibu Cove Colony Dr",
                            "Price": "$45,000/Lease",
                            "Date": "7/24/20"
                        },
                         {
                            "Address": "427 Sycamore Rd",
                            "Price": "$180,000/Lease",
                            "Date": "7/24/20"
                        },
                         {
                            "Address": "1111 S. Grand Ave #1213",
                            "Price": "$84,000/Lease",
                            "Date": "7/24/20"
                        },
                         {
                            "Address": "23457 Malibu Colony Rd #87A",
                            "Price": "$360,000/Lease",
                            "Date": "7/24/20"
                        },
                        {
                            "Address": "21562 Pacific Coast Hwy",
                            "Price": "$55,000/Lease",
                            "Date": "8/19/20"
                        },
                        {
                            "Address": "17819 Castellammare Dr.",
                            "Price": "$55,000/Lease",
                            "Date": "8/19/20"
                        },
                        {
                            "Address": "Malibu Confidential",
                            "Price": "$509,000/Lease",
                            "Date": "8/27/20"
                        },
                        {
                            "Address": "23416 Malibu Colony Rd #100",
                            "Price": "$196,333.38/Lease",
                            "Date": "9/1/20"
                        },
                        {
                            "Address": "21562 Pacific Coast Hwy",
                            "Price": "$50,000/Lease",
                            "Date": "9/1/20"
                        },
                        {
                            "Address": "23517 Malibu Colony Rd. #79A",
                            "Price": "$70,000/Lease",
                            "Date": "9/2/20"
                        },
                        {
                            "Address": "2669 Larmar Rd",
                            "Price": "$162,000/Lease",
                            "Date": "9/15/20"
                        },
                        {
                            "Address": "8745 Rosewood Ave",
                            "Price": "$198,000/Lease",
                            "Date": "10/1/20"
                        },
                        {
                            "Address": "23441 Malibu Colony Rd #93A",
                            "Price": "$7,500,000",
                            "Date": "10/27/20"
                        },
                        {
                            "Address": "23350 Malibu Colony Rd #109",
                            "Price": "$10,726,088",
                            "Date": "12/15/20"
                        },
                        {
                            "Address": "23664 Malibu Colony Rd #41",
                            "Price": "$11,980,000",
                            "Date": "12/18/20"
                        },
                    ]
                },
                {
                    "year": "2019",
                    "sales": "123",
                    "data":[
                    {
                        "Address": "23457 Malibu Colony Rd #87a, Malibu",
                        "Price": "$720,000/Lease",
                        "Date": "2/13/19"
                    },
                    {
                        "Address": "23430 Malibu Colony Rd #95, Malibu",
                        "Price": "$175,000/Lease",
                        "Date": "2/28/19"
                    },
                    {
                        "Address": "411 N Oakhurst Dr #404, Beverly Hills",
                        "Price": "$84,000/Lease",
                        "Date": "4/4/19"
                    },
                    {
                        "Address": "460 N Palm #102, Beverly Hills",
                        "Price": "$2,300,000",
                        "Date": "4/19/19"
                    },
                    {
                        "Address": "427 Sycamore Rd, Santa Monica",
                        "Price": "$177,000/Lease",
                        "Date": "5/10/19"
                    },
                    {
                        "Address": "411 N Oakhurst Dr #111, Beverly Hills",
                        "Price": "$1,450,000",
                        "Date": "5/16/19"
                    },
                    {
                        "Address": "23430 Malibu Colony Rd #95, Malibu",
                        "Price": "$150,000/Lease",
                        "Date": "6/11/19"
                    },
                    {
                        "Address": "Heakcock St/Allessandro, Moreno Valley",
                        "Price": "$4,618,013.40/Referral",
                        "Date": "6/12/19"
                    },
                    {
                        "Address": "23536 Malibu Colony Rd #74, Malibu",
                        "Price": "$110,000/Lease",
                        "Date": "6/13/19"
                    },
                    {
                        "Address": "8745 Rosewood Ave, West Hollywood",
                        "Price": "$195,000/Lease",
                        "Date": "7/20/19"
                    },
                    {
                        "Address": "6977 Los Tilos Rd, Los Angeles",
                        "Price": "$3,241,730",
                        "Date": "7/30/19"
                    },
                    {
                        "Address": "8561 Lookout Mountain Ave, Los Angeles",
                        "Price": "$1,100,000",
                        "Date": "8/30/19"
                    },
                    {
                        "Address": "23556 Malibu Colony Rd., Malibu",
                        "Price": "$14,745,000\n(Represented Buyer)",
                        "Date": "10/4/19"
                    },
                    {
                        "Address": "4630 Woodley Ave #101, Encino",
                        "Price": "$970,000",
                        "Date": "10/18/19"
                    },
                    {
                        "Address": "1415 Palisades Dr, Pacific Palisades",
                        "Price": "$977,500",
                        "Date": "11/08/19"
                    },
                    {
                        "Address": "653 Tularosa Dr, Los Angeles",
                        "Price": "$1,444,000",
                        "Date": "12/02/19"
                    },
                    {
                        "Address": "1014 N Hilldale Ave, West Hollywood",
                        "Price": "$1,375,000",
                        "Date": "12/23/19"
                    },
                    {
                        "Address": "1750 Westridge Road, Los Angeles",
                        "Price": "$6,547,762\n(Represented Buyer)",
                        "Date": "12/31/19"
                    },
                    ]
                },
                {
                    "year": "2018",
                    "sales": "$57,315,218",
                    "data":[
                    {
                        "Address": "23660 Malibu Colony Rd #42, Malibu",
                        "Price": "$25,395,000\n(off-market)",
                        "Date": "1/10/18"
                    },
                    {
                        "Address": "937 12th St #303, Santa Monica",
                        "Price": "$955,000",
                        "Date": "2/15/18"
                    },
                    {
                        "Address": "6907 Grasswood Ave, Malibu",
                        "Price": "$90,000/Lease",
                        "Date": "3/5/18"
                    },
                    {
                        "Address": "23410 Malibu Colony Rd #102, Malibu",
                        "Price": "$11,000,000",
                        "Date": "4/19/18"
                    },
                    {
                        "Address": "141 S Clark Dr #430, West Hollywood",
                        "Price": "$38,400/Lease",
                        "Date": "5/15/18"
                    },
                    {
                        "Address": "12139 Hollyglen Pl, Studio City",
                        "Price": "$103,935/Lease",
                        "Date": "6/11/18"
                    },
                    {
                        "Address": "23430 Malibu Colony Rd #95, Malibu",
                        "Price": "$65,000/Lease",
                        "Date": "7/17/18"
                    },
                    {
                        "Address": "23416 Malibu Colony Rd #100, Malibu",
                        "Price": "$147,333/Lease",
                        "Date": "7/20/18"
                    },
                    {
                        "Address": "21 Quarterdeck St #2, Marina Del Rey",
                        "Price": "$1,920,000",
                        "Date": "7/24/18"
                    },
                    {
                        "Address": "4264 McConnell Blvd, Culver City",
                        "Price": "$1,415,000",
                        "Date": "8/1/18"
                    },
                    {
                        "Address": "8937 Dorrington Ave, West Hollywood",
                        "Price": "$405,000/Lease",
                        "Date": "8/2/18"
                    },
                    {
                        "Address": "1100 Fallen Leaf Rd, Arcadia",
                        "Price": "$7,800,000",
                        "Date": "9/5/18"
                    },
                    {
                        "Address": "460 N Palm Dr #202, Beverly Hills",
                        "Price": "$3,300,000",
                        "Date": "10/4/18"
                    },
                    {
                        "Address": "1362 Woodruff Ave, Los Angeles",
                        "Price": "$2,226,000",
                        "Date": "11/8/18"
                    },
                    {
                        "Address": "2103 Alcyona Dr, Los Angeles",
                        "Price": "$1,734,550",
                        "Date": "12/11/18"
                    },
                    {
                        "Address": "25438 Malibu Rd, Malibu",
                        "Price": "$360,000/Lease",
                        "Date": "2018"
                    }
                    ]
                },
                {
                    "year": "2017",
                    "sales": "$37,218,690",
                    "data": [
                    {
                        "Address": "301 Trousdale Pl, Beverly Hills",
                        "Price": "$60,000/Lease",
                        "Date": "1/19/17"
                    },
                    {
                        "Address": "#42 Malibu Colony Rd, Malibu",
                        "Price": "$27,000/Lease (ext)",
                        "Date": "1/26/17"
                    },
                    {
                        "Address": "301 Trousdale Pl, Beverly Hills",
                        "Price": "$9,765/Lease (ext)",
                        "Date": "3/02/17"
                    },
                    {
                        "Address": "3621 Serra Rd., Malibu",
                        "Price": "$16,750/Lease (ext)",
                        "Date": "3/12/17"
                    },
                    {
                        "Address": "8937 Dorrington Ave., West Hollywood",
                        "Price": "$156,000/Lease",
                        "Date": "3/22/17"
                    },
                    {
                        "Address": "10960 Bellagio Rd., Los Angeles",
                        "Price": "$5,975,000\n(Represented Buyer)",
                        "Date": "5/25/17"
                    },

                    {
                        "Address": "10960 Bellagio Rd., Los Angeles",
                        "Price": "$5,975,000\n(Represented Seller)",
                        "Date": "5/25/17"
                    },
                    {
                        "Address": "23430 Malibu Colony Rd #95, Malibu",
                        "Price": "$200,000/Lease",
                        "Date": "6/2/17"
                    },
                    {
                        "Address": "23416 Malibu Colony Rd #100, Malibu",
                        "Price": "$240,000/Lease",
                        "Date": "6/11/17"
                    },
                    {
                        "Address": "5625 Round Meadow Rd, Hidden Hills",
                        "Price": "$6,400,000",
                        "Date": "6/26/17"
                    },
                    {
                        "Address": "3621 Serra Rd, Malibu",
                        "Price": "$2,675,000",
                        "Date": "8/17/17"
                    },
                    {
                        "Address": "23649 Malibu Colony Rd #46a, Malibu",
                        "Price": "$7,250,000",
                        "Date": "10/4/17"
                    },
                    {
                        "Address": "23555 Malibu Colony Rd, Malibu",
                        "Price": "$6,800,000",
                        "Date": "10/31/17"
                    },
                    {
                        "Address": "10490 Wilshire Blvd #502, Westwood",
                        "Price": "$1,253,500",
                        "Date": "10/31/17"
                    },
                    {
                        "Address": "2103 Alcyona Dr, Los Angeles",
                        "Price": "$72,000/Lease",
                        "Date": "11/15/17"
                    },
                    {
                        "Address": "23567 Malibu Colony Rd #64A, Malibu",
                        "Price": "$108,675/Lease (ext)",
                        "Date": "11/28/17"
                    }
                    ]
                },
                {
                    "year": "2016",
                    "sales": "$29,409,999",
                    "data": [
                    {
                        "Address": "4424 Whitsett Ave #314, Studio City",
                        "Price": "$625,000",
                        "Date": "1/29/16"
                    },
                    {
                        "Address": "#100 Malibu Colony Rd, Malibu",
                        "Price": "$144,000/Lease",
                        "Date": "3/14/16"
                    },
                    {
                        "Address": "#56 Malibu Colony Rd, Malibu",
                        "Price": "$100,000/Lease",
                        "Date": "3/14/16"
                    },
                    {
                        "Address": "1479 Carla Ridge, Beverly Hills",
                        "Price": "$240,000/Lease",
                        "Date": "3/15/16"
                    },
                    {
                        "Address": "#56 Malibu Colony Rd, Malibu",
                        "Price": "$100,000/Lease",
                        "Date": "3/16/16"
                    },
                    {
                        "Address": "8937 Dorrington Ave, West Hollywood",
                        "Price": "$2,220,000",
                        "Date": "4/15/16"
                    },
                    {
                        "Address": "#100 Malibu Colony Rd., Malibu",
                        "Price": "$277,333/Lease",
                        "Date": "4/18/16"
                    },
                    {
                        "Address": "625 Old Mill Rd, Pasadena",
                        "Price": "$1,780,000/Referral",
                        "Date": "5/10/16"
                    },
                    {
                        "Address": "1100 Fallen Leaf Rd., Arcadia",
                        "Price": "$9,380,000",
                        "Date": "6/17/16"
                    },
                    {
                        "Address": "10445 Wilshire Blvd. #1805, Los Angeles",
                        "Price": "$1,250,000",
                        "Date": "6/21/16"
                    },
                    {
                        "Address": "9250 Cordell Dr., Los Angeles",
                        "Price": "$3,875,000",
                        "Date": "7/01/16"
                    },
                    {
                        "Address": "#31A Malibu Colony Rd., Malibu",
                        "Price": "$120,000/Lease",
                        "Date": "7/08/16"
                    },
                    {
                        "Address": "#42 Malibu Colony Rd., Malibu",
                        "Price": "$95,000/Lease",
                        "Date": "8/25/16"
                    },
                    {
                        "Address": "#100 Malibu Colony Rd., Malibu",
                        "Price": "$29,166/Lease",
                        "Date": "8/30/16"
                    },
                    {
                        "Address": "617 N Cherokee Ave., Los Angeles",
                        "Price": "$2,400,000",
                        "Date": "9/01/16"
                    },
                    {
                        "Address": "#42 Malibu Colony Rd., Malibu",
                        "Price": "$600,000/Lease",
                        "Date": "9/05/16"
                    },
                    {
                        "Address": "425 N Maple Dr #304, Beverly Hills",
                        "Price": "$2,495,000",
                        "Date": "9/07/16"
                    },
                    {
                        "Address": "19056 Pacific Coast Hwy., Malibu",
                        "Price": "$75,000/Lease",
                        "Date": "9/27/16"
                    },
                    {
                        "Address": "10445 Wilshire Blvd. #1406, Los Angeles",
                        "Price": "$940,000",
                        "Date": "10/25/16"
                    },
                    {
                        "Address": "430 N Oakhurst Dr. #203, Beverly Hills",
                        "Price": "$1,485,000",
                        "Date": "10/31/16"
                    },
                    {
                        "Address": "#64A Malibu Colony Rd., Malibu",
                        "Price": "$138,000/Lease",
                        "Date": "11/11/16"
                    },
                    {
                        "Address": "25438 Malibu Rd, Malibu",
                        "Price": "$400,000/Lease",
                        "Date": "12/15/16"
                    },
                    {
                        "Address": "#42 Malibu Colony Rd, Malibu",
                        "Price": "$598,500/Lease",
                        "Date": "12/18/16"
                    },
                    {
                        "Address": "3621 Serra Rd, Malibu",
                        "Price": "$43,000/Lease",
                        "Date": "12/27/16"
                    }
                    ]
                },
                {
                    "year": "2015",
                    "sales": "$82,319,841",
                    "data": [
                    {
                        "Address": "#42 Malibu Colony Rd, Malibu",
                        "Price": "$400,000/Lease",
                        "Date": "2/12/15"
                    },
                    {
                        "Address": "24 Eastwind St #1, Marina Del Rey",
                        "Price": "$1,835,000",
                        "Date": "3/10/15"
                    },
                    {
                        "Address": "23560 Malibu Colony Rd, Malibu",
                        "Price": "$10,382,500\n(per MLS)",
                        "Date": "3/18/15"
                    },
                    {
                        "Address": "603 N Bedford Dr, Beverly Hills",
                        "Price": "$5,200,000",
                        "Date": "3/19/15"
                    },
                    {
                        "Address": "581 Amalfi Dr, Pacific Palisades",
                        "Price": "$10,459,460",
                        "Date": "3/20/15"
                    },
                    {
                        "Address": "27400 Pacific Coast Hwy #105, Malibu",
                        "Price": "$25,000/Lease",
                        "Date": "3/24/15"
                    },
                    {
                        "Address": "430 N Oakhurst Dr, Beverly Hills",
                        "Price": "$198,000/Lease",
                        "Date": "4/8/15"
                    },
                    {
                        "Address": "31537 Pacific Coast Hwy, Malibu",
                        "Price": "$2,246,184",
                        "Date": "4/10/15"
                    },
                    {
                        "Address": "7111 Sepulveda Blvd, Van Nuys",
                        "Price": "$4,940,000/Referral",
                        "Date": "4/20/15"
                    },
                    {
                        "Address": "21 Quarterdeck St #B, Marina Del Rey",
                        "Price": "$1,744,000",
                        "Date": "5/11/15"
                    },
                    {
                        "Address": "#100 Malibu Colony Rd, Malibu",
                        "Price": "$250,000/Lease",
                        "Date": "6/16/15"
                    },
                    {
                        "Address": "23538 Malibu Colony Rd, Malibu",
                        "Price": "$9,980,000\n(Represented Buyer)",
                        "Date": "7/9/15"
                    },
                    {
                        "Address": "23538 Malibu Colony Rd, Malibu",
                        "Price": "$9,980,000\n(Represented Seller)",
                        "Date": "7/9/15"
                    },
                    {
                        "Address": "853 Larrabee Street #6, West Hollywood",
                        "Price": "$715,000",
                        "Date": "7/31/15"
                    },
                    {
                        "Address": "#42 Malibu Colony Rd, Malibu",
                        "Price": "$550,000/Lease",
                        "Date": "8/27/15"
                    },
                    {
                        "Address": "23533 Malibu Colony Rd, Malibu",
                        "Price": "$4,988,000\n(Represented Buyer)",
                        "Date": "9/02/15"
                    },
                    {
                        "Address": "23533 Malibu Colony Rd, Malibu",
                        "Price": "$4,988,000\n(Represented Seller)",
                        "Date": "9/02/15"
                    },
                    {
                        "Address": "460 Palm, Beverly Hills",
                        "Price": "$1,950,000",
                        "Date": "10/1/15"
                    },
                    {
                        "Address": "1479 Carla Ridge, Beverly Hills",
                        "Price": "$480,000/Lease",
                        "Date": "10/7/15"
                    },
                    {
                        "Address": "9049 Harland, West Hollywood",
                        "Price": "$126,000/Lease",
                        "Date": "10/15/15"
                    },
                    {
                        "Address": "320 S Gramercy #303, Los Angeles",
                        "Price": "$281,000",
                        "Date": "10/16/15"
                    },
                    {
                        "Address": "#102 Malibu Colony Rd, Malibu",
                        "Price": "$10,000/Lease",
                        "Date": "10/22/15"
                    },
                    {
                        "Address": "11755 1/2 Moorpark, Studio City",
                        "Price": "$20,340/lease",
                        "Date": "10/23/15"
                    },
                    {
                        "Address": "801 S Grand #1306, Los Angeles",
                        "Price": "$38,400/lease",
                        "Date": "11/9/15"
                    },
                    {
                        "Address": "1 Northstar St #PH4, Marina Del Rey",
                        "Price": "$2,592,490",
                        "Date": "11/10/15"
                    },
                    {
                        "Address": "#95 Malibu Colony Rd, Malibu",
                        "Price": "$170,000/Lease",
                        "Date": "11/14/15"
                    },
                    {
                        "Address": "2670 Carmar Drive, Los Angeles",
                        "Price": "$147,000/Lease",
                        "Date": "12/19/15"
                    },
                    {
                        "Address": "1362 Woodruff Ave, Los Angeles",
                        "Price": "$1,973,467",
                        "Date": "12/30/15"
                    },
                    {
                        "Address": "Confidential Sales",
                        "Price": "$5,650,000",
                        "Date": "2015"
                    }
                    ]
                },
                {
                    "year": "2014",
                    "sales": "$53,946,000",
                    "data": [
                    {
                        "Address": "31952 Pacific Coast Hwy #1/2, Malibu",
                        "Price": "$234,000/Lease",
                        "Date": "2/5/14"
                    },
                    {
                        "Address": "#42 Malibu Colony Rd, Malibu",
                        "Price": "$17,375,000",
                        "Date": "2/24/14"
                    },
                    {
                        "Address": "13700 Marina Pointe Dr #1706,\nMarina del Rey",
                        "Price": "$1,265,000",
                        "Date": "3/17/14"
                    },
                    {
                        "Address": "801 S Grand Ave #1302, Los Angeles",
                        "Price": "$725,000",
                        "Date": "3/24/14"
                    },
                    {
                        "Address": "25438 Malibu Rd, Malibu",
                        "Price": "$720,000/Lease",
                        "Date": "3/6/14"
                    },
                    {
                        "Address": "623 N Alta Dr, Beverly Hills",
                        "Price": "$21,000/Lease",
                        "Date": "3/17/14"
                    },
                    {
                        "Address": "#42 Malibu Colony Rd, Malibu",
                        "Price": "$175,000/Lease",
                        "Date": "3/18/14"
                    },
                    {
                        "Address": "618 N Sierra Dr, Beverly Hills",
                        "Price": "$8,500,000",
                        "Date": "3/28/14"
                    },
                    {
                        "Address": "404 Wekiu Place, Maui, Hawaii",
                        "Price": "$1,975,000/Referral",
                        "Date": "4/22/14"
                    },
                    {
                        "Address": "#95 Malibu Colony Rd, Malibu",
                        "Price": "$140,000/Lease",
                        "Date": "4/25/14"
                    },
                    {
                        "Address": "21634 Pacific Coast Hwy, Malibu",
                        "Price": "$66,000/Lease",
                        "Date": "4/29/14"
                    },
                    {
                        "Address": "9255 Doheny Rd #2201, West Hollywood",
                        "Price": "$3,500,000",
                        "Date": "5/28/14"
                    },
                    {
                        "Address": "76228 Via Montelena, Indian Wells",
                        "Price": "$700,000/Referral",
                        "Date": "6/6/14"
                    },
                    {
                        "Address": "#42 Malibu Colony Rd., Malibu",
                        "Price": "$130,000/Lease",
                        "Date": "6/8/14"
                    },
                    {
                        "Address": "#102 Malibu Colony Rd, Malibu",
                        "Price": "$23,750/Lease",
                        "Date": "6/26/14"
                    },
                    {
                        "Address": "430 Oakhurst Dr., Beverly Hills",
                        "Price": "$62,400/Lease",
                        "Date": "7/1/14"
                    },
                    {
                        "Address": "2547 Hutton Dr., Beverly Hills",
                        "Price": "$20,000/Lease",
                        "Date": "7/15/14"
                    },
                    {
                        "Address": "201 Ocean Ave. #P306, Santa Monica",
                        "Price": "$72,000/Lease",
                        "Date": "7/20/14"
                    },
                    {
                        "Address": "6766 Portshead Rd., Malibu",
                        "Price": "$90,000/Lease",
                        "Date": "7/25/14"
                    },
                    {
                        "Address": "3621 Serra Rd., Malibu",
                        "Price": "$126,000/Lease",
                        "Date": "7/28/14"
                    },
                    {
                        "Address": "#46A Malibu Colony Rd., Malibu",
                        "Price": "$35,000/Lease",
                        "Date": "7/29/14"
                    },
                    {
                        "Address": "914 14th St. #101, Santa Monica",
                        "Price": "$1,550,000",
                        "Date": "10/02/14"
                    },
                    {
                        "Address": "#46A Malibu Colony Rd., Malibu",
                        "Price": "$5,550,000",
                        "Date": "10/09/14"
                    },
                    {
                        "Address": "9249 Robin Dr., Los Angeles",
                        "Price": "$8,200,000",
                        "Date": "10/20/14"
                    },
                    {
                        "Address": "523 11th St., Santa Monica",
                        "Price": "$3,250,000",
                        "Date": "10/30/14"
                    }
                    ]
                },
                {
                    "year": "2013",
                    "sales": "$51,684,961",
                    "data": [
                    {
                        "Address": "4561 Northside Dr, Atlanta",
                        "Price": "$3,325,000/Referral",
                        "Date": "1/7/13"
                    },
                    {
                        "Address": "7995 Briar Summit Dr, Los Angeles",
                        "Price": "$18,000/Lease",
                        "Date": "1/7/13"
                    },
                    {
                        "Address": "1915 Pine Street, Boulder, CO",
                        "Price": "$888,290/Referral",
                        "Date": "2/1/13"
                    },
                    {
                        "Address": "#95 Malibu Colony Rd, Malibu",
                        "Price": "$130,000/Lease",
                        "Date": "3/2/13"
                    },
                    {
                        "Address": "23834 Malibu Rd, Malibu",
                        "Price": "$69,000/Lease",
                        "Date": "3/5/13"
                    },
                    {
                        "Address": "8745 Rosewood Ave, West Hollywood",
                        "Price": "$2,450,000",
                        "Date": "3/15/13"
                    },
                    {
                        "Address": "801 S Grand Ave #1306, Los Angeles",
                        "Price": "$445,000",
                        "Date": "3/29/13"
                    },
                    {
                        "Address": "2383 Bowmont Dr, Beverly Hills",
                        "Price": "$68,871/Lease",
                        "Date": "3/31/13"
                    },
                    {
                        "Address": "#92A Malibu Colony Rd, Malibu",
                        "Price": "$35,000/Lease",
                        "Date": "4/11/13"
                    },
                    {
                        "Address": "1520 Gilcrest Dr, Beverly Hills",
                        "Price": "$8,500,000",
                        "Date": "4/18/13"
                    },
                    {
                        "Address": "430 N Oakhurst Dr #203, Beverly Hills",
                        "Price": "$1,200,000",
                        "Date": "4/22/13"
                    },
                    {
                        "Address": "#95 Malibu Colony Rd, Malibu",
                        "Price": "$120,000/Lease",
                        "Date": "4/29/13"
                    },
                    {
                        "Address": "425 N Maple Dr #304, Beverly Hills",
                        "Price": "$1,910,000",
                        "Date": "5/20/13"
                    },
                    {
                        "Address": "16557 Goldenrod Pl, Encino",
                        "Price": "$119,000/Lease",
                        "Date": "5/30/13"
                    },
                    {
                        "Address": "#87A Malibu Colony Rd, Malibu",
                        "Price": "$32,500/Lease",
                        "Date": "6/27/13"
                    },
                    {
                        "Address": "13824 La Maida St, Sherman Oaks",
                        "Price": "$718,000",
                        "Date": "8/21/13"
                    },
                    {
                        "Address": "2753 Laurel Pass, Los Angeles",
                        "Price": "$10,500/Lease",
                        "Date": "9/27/13"
                    },
                    {
                        "Address": "9313 Doheny Rd, Beverly Hills",
                        "Price": "$10,800/Lease",
                        "Date": "10/28/13"
                    },
                    {
                        "Address": "21952 Avenida de Arboles, Murrieta",
                        "Price": "$3,300,000",
                        "Date": "11/4/13"
                    },
                    {
                        "Address": "9525 Dalegrove Drive, Beverly Hills",
                        "Price": "$1,975,000",
                        "Date": "11/14/13"
                    },
                    {
                        "Address": "4424 Whitsett Ave #314, Studio City",
                        "Price": "$610,000",
                        "Date": "12/16/13"
                    },
                    {
                        "Address": "Confidential Sales",
                        "Price": "$25,750,000",
                        "Date": "-"
                    }
                    ]
                },
                {
                    "year": "2012",
                    "sales": "$55,128,181",
                    "data": [
                    {
                        "Address": "#82 Malibu Colony Rd, Malibu",
                        "Price": "$287,200/Lease",
                        "Date": "1/1/12"
                    },
                    {
                        "Address": "1900 Sunset Plaza Dr, Los Angeles",
                        "Price": "$35,000/Lease",
                        "Date": "1/10/12"
                    },
                    {
                        "Address": "56 Beverly Park Way, Beverly Hills",
                        "Price": "$21,750,000",
                        "Date": "2/7/12"
                    },
                    {
                        "Address": "#95 Malibu Colony Rd, Malibu",
                        "Price": "$250,000/Lease",
                        "Date": "2/9/12"
                    },
                    {
                        "Address": "121 S Hope St #6, Los Angeles",
                        "Price": "$343,820",
                        "Date": "2/22/12"
                    },
                    {
                        "Address": "9648 Highridge Dr, Beverly Hills",
                        "Price": "$90,000/Lease",
                        "Date": "2/22/12"
                    },
                    {
                        "Address": "#28 Malibu Colony Rd, Malibu",
                        "Price": "$136,161/Lease",
                        "Date": "3/12/12"
                    },
                    {
                        "Address": "7377 Birdview Ave, Malibu",
                        "Price": "$21,000,000",
                        "Date": "4/6/12"
                    },
                    {
                        "Address": "#66A Malibu Colony Rd, Malibu",
                        "Price": "$180,000/Lease",
                        "Date": "5/5/12"
                    },
                    {
                        "Address": "48 Sawgrass, Coto de Caza",
                        "Price": "$942,000/Referral",
                        "Date": "5/18/12"
                    },
                    {
                        "Address": "#92a Malibu Colony Rd, Malibu",
                        "Price": "$20,000/Lease",
                        "Date": "6/29/12"
                    },
                    {
                        "Address": "801 S Grand Ave #1302, Los Angeles",
                        "Price": "$445,000",
                        "Date": "6/29/12"
                    },
                    {
                        "Address": "1129 N Poinsettia Pl, West Hollywood",
                        "Price": "$899,000",
                        "Date": "7/30/12"
                    },
                    {
                        "Address": "801 S Grand Ave #1302, Los Angeles",
                        "Price": "$36,000/Lease",
                        "Date": "8/8/12"
                    },
                    {
                        "Address": "3274 Hutton Dr, Beverly Hills",
                        "Price": "$132,000/Lease",
                        "Date": "10/5/12"
                    },
                    {
                        "Address": "#46A Malibu Colony Rd, Malibu",
                        "Price": "$252,000/Lease",
                        "Date": "11/2/12"
                    },
                    {
                        "Address": "13 Seaview Dr, Montecito",
                        "Price": "$4,650,000",
                        "Date": "11/15/12"
                    },
                    {
                        "Address": "19064 Wells Dr, Tarzana",
                        "Price": "$2,345,000",
                        "Date": "11/21/12"
                    },
                    {
                        "Address": "Confidential Sale",
                        "Price": "$1,335,000",
                        "Date": "12/2012"
                    }
                    ]
                },
                {
                    "year": "2011",
                    "sales": "$26,176,400",
                    "data": [
                    {
                        "Address": "#102 Malibu Colony Rd, Malibu",
                        "Price": "$160,000/Lease",
                        "Date": "1/11/11"
                    },
                    {
                        "Address": "1511 San Ysidro Drive, Beverly Hills",
                        "Price": "$1,610,000\n(Represented Buyer)",
                        "Date": "1/21/11"
                    },
                    {
                        "Address": "1511 San Ysidro Drive, Beverly Hills",
                        "Price": "$1,610,000\n(Represented Seller)",
                        "Date": "1/21/11"
                    },
                    {
                        "Address": "16557 Goldenrod, Encino",
                        "Price": "$105,000/Lease",
                        "Date": "1/26/11"
                    },
                    {
                        "Address": "801 S Grand #2001, Los Angeles",
                        "Price": "$625,000",
                        "Date": "3/18/11"
                    },
                    {
                        "Address": "914 14th St #101, Santa Monica",
                        "Price": "$1,150,000",
                        "Date": "3/31/11"
                    },
                    {
                        "Address": "12308 Califa St, Valley Village",
                        "Price": "$630,000",
                        "Date": "4/13/11"
                    },
                    {
                        "Address": "1865 Heather Ct, Beverly Hills",
                        "Price": "$2,425,000",
                        "Date": "4/15/11"
                    },
                    {
                        "Address": "22102 Pacific Coast Hwy, Malibu",
                        "Price": "$85,000/Lease",
                        "Date": "5/16/11"
                    },
                    {
                        "Address": "#95 Malibu Colony Rd, Malibu",
                        "Price": "$125,000/Lease",
                        "Date": "5/19/11"
                    },
                    {
                        "Address": "#95 Malibu Colony Rd, Malibu",
                        "Price": "$125,000/Lease",
                        "Date": "5/24/11"
                    },
                    {
                        "Address": "2260 Sunset Plaza Dr, Los Angeles",
                        "Price": "$60,000/Lease",
                        "Date": "5/25/11"
                    },
                    {
                        "Address": "1516 Hillcrest Ave, Glendale",
                        "Price": "$71,400/Lease",
                        "Date": "8/15/11"
                    },
                    {
                        "Address": "5803 Wilkinson Ave, Los Angeles",
                        "Price": "$535,000",
                        "Date": "9/19/11"
                    },
                    {
                        "Address": "1677 N. Crescent Heights Blvd, \nLos Angeles",
                        "Price": "$2,400,000",
                        "Date": "10/17/11"
                    },
                    {
                        "Address": "3542 Multiview Dr, \nLos Angeles",
                        "Price": "$1,460,000",
                        "Date": "12/1/11"
                    },
                    {
                        "Address": "Confidential Sale",
                        "Price": "$13,000,000",
                        "Date": "12/2011"
                    }
                    ]
                },
                {
                    "year": "2010",
                    "sales": "$82,220,500",
                    "data": [
                    {
                        "Address": "10960 Bellagio Rd, Bel-Air",
                        "Price": "$5,200,000",
                        "Date": "2/9/10"
                    },
                    {
                        "Address": "6166 Ramirez Canyon, Malibu",
                        "Price": "$2,600,000",
                        "Date": "6/11/10"
                    },
                    {
                        "Address": "2717 Shell St, Newport Beach",
                        "Price": "$5,500,000",
                        "Date": "6/15/10"
                    },
                    {
                        "Address": "23816 Malibu Rd, Malibu",
                        "Price": "$90,000/Lease",
                        "Date": "6/23/10"
                    },
                    {
                        "Address": "705 Palisades Beach Rd, Santa Monica",
                        "Price": "$6,862,000",
                        "Date": "6/25/10"
                    },
                    {
                        "Address": "53 Malibu Colony, Malibu",
                        "Price": "$60,000/Lease",
                        "Date": "7/14/10"
                    },
                    {
                        "Address": "44665 La Cruz Dr, Temecula",
                        "Price": "$4,800,000",
                        "Date": "7/27/10"
                    },
                    {
                        "Address": "23360 Malibu Colony, Malibu",
                        "Price": "$14,950,000\n(Represented Buyer)",
                        "Date": "8/9/10"
                    },
                    {
                        "Address": "23360 Malibu Colony, Malibu",
                        "Price": "$14,950,000\n(Represented Seller)",
                        "Date": "8/9/10"
                    },
                    {
                        "Address": "64 Malibu Colony Rd, Malibu",
                        "Price": "$20,000/Lease",
                        "Date": "8/24/10"
                    },
                    {
                        "Address": "11279 Charnock Rd, Mar Vista",
                        "Price": "$647,000",
                        "Date": "9/25/10"
                    },
                    {
                        "Address": "23406 Malibu Colony Rd, Malibu",
                        "Price": "$75,000/Lease",
                        "Date": "9/30/10"
                    },
                    {
                        "Address": "3600 Coldwater Canyon, Studio City",
                        "Price": "$1,139,000",
                        "Date": "10/5/10"
                    },
                    {
                        "Address": "8817 Thrasher Ave, Los Angeles",
                        "Price": "$1,827,500",
                        "Date": "11/18/10"
                    },
                    {
                        "Address": "77 Beverly Park Ln, Beverly Hills",
                        "Price": "$23,500,000",
                        "Date": "12/15/10"
                    }
                    ]
                }
                ]
        }
    }
}
</script>
